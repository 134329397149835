import { React } from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '../../assets/destaque.png';
import imgPatrocinio from '../../assets/logos-destaque.png';

import './Home.css';

function Home() {
	return (
		<div class="container-lg text-center">
			<div class="row justify-content-center">
				<div class="col-lg-6 col-md-8 col-sm-10">
					<img src={imgLogo} alt="logo-associacao" className="img-logo" />
				</div>
				<div class="col-12 mb-3">
					<a href="/login">
						<button class="home-button">INICIAR</button>
					</a>
				</div>
				<div className="card col-lg-6 col-md-10 col-sm-10">
					<img src={imgPatrocinio} class="card-img-top" alt="..." />
					<div class="card-body">
						<p class="card-text h5">
							<strong>Patronicadores Oficiais</strong>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
