import React from 'react';
import { Navigate } from 'react-router-dom';
import { localStorageHelper } from '../utils/LocalStorageHelper';

const ProtectedRoute = ({ element, ...rest }) => {
	const isAuthenticated = localStorageHelper.getItem('token');

	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}

	return element;
};

export default ProtectedRoute;
